const subdomain = window.location.host.split('.')[0];

const prod = {
    APP_NAME: 'empathize',
    //APP_LOGO_URL: '/assets/images/user.png',
    APP_LOGO_URL: '/assets/images/b-empathize.png',
    DEBUG_ENABLED: false,
    FEATURES_PREVIEW_ENABLED: false,
    GRAPHQL_API_BASE_URL: 'https://' + subdomain + '.api.empathize.pt/graphql',
};

const qa = {
    APP_NAME: 'empathize (Testes)',
    //APP_LOGO_URL: '/assets/images/user.png',
    APP_LOGO_URL: '/assets/images/b-empathize.png',
    DEBUG_ENABLED: false,
    FEATURES_PREVIEW_ENABLED: false,
    GRAPHQL_API_BASE_URL: 'https://' + subdomain + '.api.qa.empathize.pt/graphql',
};


const dev = {
    APP_NAME: 'empathize (DEV)',
    //APP_LOGO_URL: '/assets/images/user.png',
    APP_LOGO_URL: '/assets/images/b-empathize.png',
    DEBUG_ENABLED: true,
    FEATURES_PREVIEW_ENABLED: false,
    GRAPHQL_API_BASE_URL: 'http://10.0.200.100:8080/graphql',
};

const devPublic = {
    APP_NAME: 'empathize (DEV Public)',
    //APP_LOGO_URL: '/assets/images/user.png',
    APP_LOGO_URL: '/assets/images/b-empathize.png',
    DEBUG_ENABLED: true,
    FEATURES_PREVIEW_ENABLED: false,
    GRAPHQL_API_BASE_URL: 'https://' + subdomain + '.api.dev.empathize.pt/graphql',
};

//const ACTIVE_ENVIRONMENT = dev;
//const ACTIVE_ENVIRONMENT = qa;

//export const config = ACTIVE_ENVIRONMENT || (process.env.NODE_ENV === 'development' ? dev : (process.env.NODE_ENV === 'qa' ? qa : prod));
export const config = process.env.NODE_ENV === 'production' ? prod : (process.env.NODE_ENV === 'test' ? qa : dev);


// GraphQL API
export const GRAPHQL_MAX_RESULTS_LIMIT = 2147483647;
